<template>
  <div class="request-container">
    <BackNav
      @click="
        customer.addressSorted &&
        customer.identityVerificationCompleted &&
        virtualCards.length > 0
          ? $router.push('/drawer/card')
          : $router.push('/drawer')
      "
    />
    <h1 class="text-lg font-bold">Request for a card</h1>
    <!-- if KYC is completed i.e address and identity -->
    <div
      v-if="customer.addressSorted && customer.identityVerificationCompleted"
    >
      <p class="text-grey text-sm">Do more with your Zilla credit cards.</p>

      <Card class="mt-6 cursor-pointer" p="p-0">
        <div class="grid grid-cols-2 cards" @click="setCardType('VIRTUAL')">
          <div class="p-4 flex">
            <div class="pt-4">
              <h3 class="md:text-xl text-lg primary-text font-bold">
                Virtual card
              </h3>
              <p class="mt-3 text-grey text-sm">Create your card instantly</p>
              <p class="mt-3 text-grey text-sm">Create up to 5 virtual cards</p>
              <p class="mt-3 text-grey text-sm">
                Make payment at your favorite online stores.
              </p>
            </div>
          </div>
          <div class="flex items-end justify-end">
            <img
              class="max-w-full h-auto rounded-br-lg"
              src="https://res.cloudinary.com/zillaafrica/image/upload/v1659434849/customer/virtual_card_citjex.png"
              alt=""
            />
          </div>
        </div>
      </Card>

      <Card class="mt-6 relative" p="p-0">
        <p
          class="pointer-events-none absolute right-2 top-3 text-teal-400 md:ml-6 rounded-full border border-teal-300 bg-teal-100 text-xs md:text-sm px-3 py-2"
        >
          Coming soon
        </p>
        <div
          class="grid grid-cols-2 cards relative pointer-events-none cursor-no-drop opacity-50"
          @click="setCardType('PHYSICAL')"
        >
          <div class="p-4 flex">
            <div class="pt-4">
              <h3 class="text-dark text-lg md:text-xl font-bold">
                Physical card
              </h3>
              <p class="mt-3 text-grey text-sm">
                Get it delivered to your doorstep
              </p>
              <p class="mt-3 text-grey text-sm">
                Make payment online and in-store
              </p>
            </div>
          </div>
          <div class="flex items-end justify-end">
            <img
              class="max-w-full h-auto rounded-br-lg"
              src="https://res.cloudinary.com/zillaafrica/image/upload/v1659434865/customer/physical_card_ugncjo.png"
              alt=""
            />
          </div>
        </div>
      </Card>
    </div>
    <div v-else class="spending-container pt-6">
      <img
        src="https://res.cloudinary.com/zillaafrica/image/upload/v1664457156/customer/image_66_cv1ox3.png"
        alt=""
        class="rounded-t-lg"
      />
      <div class="bg-white px-4 py-6 rounded-b-lg">
        <h2 class="text-brandPurple text-xl font-semibold">
          Verify identity to get started
        </h2>
        <p class="text-grey py-4">
          Shop more and make payment with more merchants with Zilla cards. To
          qualify for cards, you need to verify your identity.
        </p>
        <Button
          text="Get started"
          class="w-full mt-4"
          outline
          @click="getStarted()"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { Button } from "@/UI/Button";
import { BackNav } from "@/UI/Navigation";
import { mapActions, mapState } from "vuex";
export default {
  components: {
    Button,
    BackNav,
  },
  computed: {
    ...mapState({
      customer: (state) => state?.auth?.user,
      virtualCards: (state) => state?.card?.virtualCards,
    }),
  },
  methods: {
    ...mapActions("card", ["updateCardType"]),
    ...mapActions("dashboard", ["showKycStep"]),
    setCardType(type) {
      this.updateCardType(type);
      this.$router.push("/drawer/card/customize");
    },
    getStarted() {
      this.$router.push("/");
      setTimeout(() => {
        this.showKycStep(true);
      }, 1000);
    },
  },
};
</script>
<style scoped>
.cards {
  min-height: 11.25rem;
}
.request-container {
  max-width: 36.563rem;
  margin: auto;
}
</style>
