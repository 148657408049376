var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "request-container" },
    [
      _c("BackNav", {
        on: {
          click: function ($event) {
            _vm.customer.addressSorted &&
            _vm.customer.identityVerificationCompleted &&
            _vm.virtualCards.length > 0
              ? _vm.$router.push("/drawer/card")
              : _vm.$router.push("/drawer")
          },
        },
      }),
      _c("h1", { staticClass: "text-lg font-bold" }, [
        _vm._v("Request for a card"),
      ]),
      _vm.customer.addressSorted && _vm.customer.identityVerificationCompleted
        ? _c(
            "div",
            [
              _c("p", { staticClass: "text-grey text-sm" }, [
                _vm._v("Do more with your Zilla credit cards."),
              ]),
              _c(
                "Card",
                { staticClass: "mt-6 cursor-pointer", attrs: { p: "p-0" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "grid grid-cols-2 cards",
                      on: {
                        click: function ($event) {
                          return _vm.setCardType("VIRTUAL")
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "p-4 flex" }, [
                        _c("div", { staticClass: "pt-4" }, [
                          _c(
                            "h3",
                            {
                              staticClass:
                                "md:text-xl text-lg primary-text font-bold",
                            },
                            [_vm._v(" Virtual card ")]
                          ),
                          _c("p", { staticClass: "mt-3 text-grey text-sm" }, [
                            _vm._v("Create your card instantly"),
                          ]),
                          _c("p", { staticClass: "mt-3 text-grey text-sm" }, [
                            _vm._v("Create up to 5 virtual cards"),
                          ]),
                          _c("p", { staticClass: "mt-3 text-grey text-sm" }, [
                            _vm._v(
                              " Make payment at your favorite online stores. "
                            ),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "flex items-end justify-end" }, [
                        _c("img", {
                          staticClass: "max-w-full h-auto rounded-br-lg",
                          attrs: {
                            src: "https://res.cloudinary.com/zillaafrica/image/upload/v1659434849/customer/virtual_card_citjex.png",
                            alt: "",
                          },
                        }),
                      ]),
                    ]
                  ),
                ]
              ),
              _c(
                "Card",
                { staticClass: "mt-6 relative", attrs: { p: "p-0" } },
                [
                  _c(
                    "p",
                    {
                      staticClass:
                        "pointer-events-none absolute right-2 top-3 text-teal-400 md:ml-6 rounded-full border border-teal-300 bg-teal-100 text-xs md:text-sm px-3 py-2",
                    },
                    [_vm._v(" Coming soon ")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "grid grid-cols-2 cards relative pointer-events-none cursor-no-drop opacity-50",
                      on: {
                        click: function ($event) {
                          return _vm.setCardType("PHYSICAL")
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "p-4 flex" }, [
                        _c("div", { staticClass: "pt-4" }, [
                          _c(
                            "h3",
                            {
                              staticClass:
                                "text-dark text-lg md:text-xl font-bold",
                            },
                            [_vm._v(" Physical card ")]
                          ),
                          _c("p", { staticClass: "mt-3 text-grey text-sm" }, [
                            _vm._v(" Get it delivered to your doorstep "),
                          ]),
                          _c("p", { staticClass: "mt-3 text-grey text-sm" }, [
                            _vm._v(" Make payment online and in-store "),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "flex items-end justify-end" }, [
                        _c("img", {
                          staticClass: "max-w-full h-auto rounded-br-lg",
                          attrs: {
                            src: "https://res.cloudinary.com/zillaafrica/image/upload/v1659434865/customer/physical_card_ugncjo.png",
                            alt: "",
                          },
                        }),
                      ]),
                    ]
                  ),
                ]
              ),
            ],
            1
          )
        : _c("div", { staticClass: "spending-container pt-6" }, [
            _c("img", {
              staticClass: "rounded-t-lg",
              attrs: {
                src: "https://res.cloudinary.com/zillaafrica/image/upload/v1664457156/customer/image_66_cv1ox3.png",
                alt: "",
              },
            }),
            _c(
              "div",
              { staticClass: "bg-white px-4 py-6 rounded-b-lg" },
              [
                _c(
                  "h2",
                  { staticClass: "text-brandPurple text-xl font-semibold" },
                  [_vm._v(" Verify identity to get started ")]
                ),
                _c("p", { staticClass: "text-grey py-4" }, [
                  _vm._v(
                    " Shop more and make payment with more merchants with Zilla cards. To qualify for cards, you need to verify your identity. "
                  ),
                ]),
                _c("Button", {
                  staticClass: "w-full mt-4",
                  attrs: { text: "Get started", outline: "" },
                  on: {
                    click: function ($event) {
                      return _vm.getStarted()
                    },
                  },
                }),
              ],
              1
            ),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }