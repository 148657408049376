<template>
  <ZillaCards />
</template>
<script>
  import ZillaCards from "@/components/ZillaCards/RequestCard";
  export default {
    components: {
      ZillaCards,
    },
  };
</script>
